import moment from 'moment';
import Term from '../components/Term';

export const knownLanguages = () => {
    return [
        { name: 'English', code: 'en-US' },
        { name: 'Français', code: 'fr' },
        { name: 'Italiano', code: 'it' },
        { name: '中文', code: 'zh-CN' },
        { name: 'Melayu', code: 'ms-MY' },
        { name: 'Suomi', code: 'fi' },
    ];
}

export const preventScrolling = () => {
    document.body.classList.add('modal-open');
}

export const enableScrolling = () => {
    document.body.classList.remove('modal-open');
}

export const parseLocalTime = (ts, renderFormat) => {
    if (!ts) return '';
    const localTime = moment(ts);
    const format = renderFormat === 'date_only' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
    if (localTime.isValid()) {
        return localTime.local().format(format);
    } else {
        return ts; // content between <ts></ts> tags as-is
    }
}

export const parseTimestamps = (text) => {
    return text.replace(/<ts[^>]*>(.*?)<\/ts>/g, (match, timestamp) => {
        return parseLocalTime(timestamp.trim());
    });
}

export const filterTags = (text) => {
    return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const getTimeAgoTranslateObj = (ts) => {
    const translationPrefix = 'CHAT.TIME_AGO';
    const currentTimeMs = Date.now();
    const diff = (new Date(currentTimeMs)).getTime() - moment.utc(ts).valueOf();

    const minutes = Math.floor((diff < 0 ? 0 : diff) / 1000 / 60);
    if (minutes < 60) {
        return { key: `${translationPrefix}_MINUTES`, n: minutes };
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return { key: `${translationPrefix}_HOURS`, n: hours };
    }

    const days = Math.floor(hours / 24);
    return { key: `${translationPrefix}_DAYS`, n: days };
}

export const parseSearchKeywords = (keywords) => {
    if (!keywords) return null;

    // turns (electrification {something}) into ({something})
    return keywords.replace(/\(electrification\s?(.*?)\)/g, '($1)');
}

/* Extends text to be rendered with known terms and their descriptions,
 * for both plain text and for content for CustomMarkdown */
export const extendTerms = (input, terms, type) => {
    if (!input) return input;

    const termList = Object.keys(terms || {});

    if (termList.length === 0) {
        return input;
    }

    const termRegex = new RegExp(`\\b(${termList.join('|')})\\b`, 'g');

    const output = input.split(termRegex).map((part, index) => {
        const matchedTerm = terms[part];
        if (matchedTerm) {
            return type === 'string'
                ? `<term definition_en="${matchedTerm.definition_en}" definition="${matchedTerm.definition}" explanation="${matchedTerm.explanation}" >${part}</term>`
                : (
                    <Term
                        key={index}
                        term={part}
                        definitionEn={matchedTerm.definition_en}
                        definition={matchedTerm.definition}
                        explanation={matchedTerm.explanation}
                    />
                );
        }
        return part;
    });

    return type === 'string'
        ? output.join('')
        : output;
};

export const extractTipImages = (contexts) => {
    if (!contexts?.length) return [];

    const extractImgTags = (content) => {
        if (!content) return [];
        const imgRegex = /<img[^>]*(?:map_id=["']([^"']+)["']|resource_id=["']([^"']+)["'])[^>]*(?:map_id=["']([^"']+)["']|resource_id=["']([^"']+)["'])[^>]*>/g;
        const matches = [...content.matchAll(imgRegex)];

        // The confusing looking match ids are there just to support any order of the map_id and resource_id
        // <img map_id="123" resource_id="456" /> -> mapId=match[1] and resourceId=match[4]
        // <img resource_id="456" map_id="123" /> -> mapId=match[3] and resourceId=match[2]
        return matches.map(match => ({
            mapId: match[1] || match[3],
            resourceId: match[2] || match[4]
        }));
    };

    return contexts
        .filter(context => context.type === 'technical_information')
        .flatMap(tip => [
            ...(tip.content ? extractImgTags(tip.content) : []),
            ...(tip.items?.flatMap(item => extractImgTags(item.content)) ?? [])
        ]);
};