import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Term = ({ term, definitionEn, definition, explanation }) => {
    const { i18n } = useTranslation();
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({
        left: '50%',
        transform: 'translateX(-50%)',
        top: '-0.5rem',
        placement: 'top'
    });
    const tooltipRef = useRef(null);

    // Make sure the tooltip is positioned correctly, not overflowing the viewport
    useEffect(() => {
        if (showTooltip && tooltipRef.current) {
            const updatePosition = () => {
                const tooltipWidth = tooltipRef.current.offsetWidth;
                const tooltipHeight = tooltipRef.current.offsetHeight;

                // in sidepanel?
                const sidepanelParent = tooltipRef.current.closest('.sidepanel-content-wrap');

                const containerWidth = sidepanelParent
                    ? sidepanelParent.offsetWidth
                    : window.innerWidth;
                const containerLeft = sidepanelParent
                    ? sidepanelParent.getBoundingClientRect().left
                    : 0;

                const elementRect = tooltipRef.current.parentElement.getBoundingClientRect();
                const elementCenterX = (elementRect.left + (elementRect.width / 2)) - containerLeft;
                const BUFFER = 5; // horizontal buffer

                let left = '50%';
                let transform = 'translateX(-50%)';

                const tooltipLeft = elementCenterX - (tooltipWidth / 2);
                const tooltipRight = elementCenterX + (tooltipWidth / 2);

                if (tooltipLeft < BUFFER) {
                    left = `calc(50% + ${-(tooltipLeft - BUFFER)}px)`;
                } else if (tooltipRight > containerWidth) {
                    left = `calc(50% - ${tooltipRight - (containerWidth - BUFFER)}px)`;
                }

                let top = '-0.5rem';
                let placement = 'top';

                const tooltipTop = elementRect.top - tooltipHeight - BUFFER;
                if (tooltipTop < 0) {
                    top = '100%';
                    placement = 'bottom';
                }

                setTooltipPosition({ left, transform, top, placement });
            };

            updatePosition();
        }
    }, [showTooltip]);

    const showLocal = i18n.language !== 'en-US';

    return (
        <span
            data-te="true"
            data-tc="Chat"
            data-ta="Term toggle (click)"
            className="term relative dashed-underline-blue cursor-pointer"
            onClick={() => setShowTooltip(!showTooltip)}
        >
            {term}
            {showTooltip && (
                <span
                    ref={tooltipRef}
                    className="term-details absolute font-normal text-gray-800 top-0 right-0 p-4 bg-white rounded-md shadow-xl z-10 w-max max-w-xs cursor-default"
                    style={{
                        ...tooltipPosition,
                        transform: `${tooltipPosition.transform} ${tooltipPosition.placement === 'top' ? 'translateY(-100%)' : ''}`
                    }}
                >
                    <span
                        data-te="true"
                        data-tc="Chat"
                        data-ta="Term toggle (close cross)"
                        id="term-close"
                        onClick={() => { setShowTooltip(false) }}
                        className="cursor-pointer absolute top-1 right-1 opacity-80 hover:opacity-100 z-50 w-10 h-10"
                    >
                        <img
                            src={`/assets/icons/close-cross.svg`}
                            alt="Close"
                            className="w-10"
                        />
                    </span>
                    <span className="term-definition pr-8 inline-block"><span className="font-bold">{term}</span> = {definitionEn} {showLocal && ` (${definition})`}</span>
                    {explanation && <span className="term-explanation block mt-2">{explanation}</span>}
                </span>
            )}
        </span>
    );
};

export default Term;